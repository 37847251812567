<template>
  <div>
    <v-app-bar
      class="print-dp-none"
      app>
      <div class="app-bar-title">
        <v-spacer></v-spacer>
        <v-btn
          small
          @click="print()">
          <v-icon class="mr-2">
            mdi-printer
          </v-icon>
          <span>Print</span>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <page-a4
        v-if="Object.keys(printProducts).length > 0"
        :print-products="printProducts"
        :purchase-order-info="purchaseOrderInfo"
        :sender-info="senderInfo"
        print-by="-"
        print-time="-"
      >
        <template v-slot:title>
          <div class="page-a4-title">
            <h1 class="mr-2">
              รายการสินค้าของ PO
            </h1>
          </div>
        </template>
      </page-a4>
    </v-main>
  </div>
</template>

<script>
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import PageA4 from '../components/PageA4.vue'

const PurchaseOrdersService = new PurchaseOrdersProvider()

export default {
  components: {
    pageA4: PageA4
  },
  data () {
    return {
      purchaseOrderInfo: {},
      printHeaderTbl: [
        { text: 'Code', align: 'text-left' },
        { text: 'Size / Color', align: 'text-left' },
        { text: 'Received', align: 'text-right' },
        { text: 'Counting', align: 'text-center' }
      ],
      printProducts: {}
    }
  },
  computed: {
    senderInfo () {
      const defaultEmptyInfo = {
        name: 'ไม่ระบุ',
        branch: 'ไม่ระบุ',
        contactName: 'ไม่ระบุ',
        phoneNumber: 'ไม่ระบุ',
        email: 'ไม่ระบุ'
      }
      return this.purchaseOrderInfo.sender ? this.purchaseOrderInfo.sender : defaultEmptyInfo
    }
  },
  async mounted () {
    await this.getOverviewByOrderNo()
  },
  methods: {
    groupProduct () {
      const products = {}
      this.purchaseOrderInfo.orders.forEach((order) => {
        if (!products[order.productId]) {
          products[order.productId] = {
            name: order.name,
            imageURL: order.imageURL,
            model: order.productModel,
            quantity: 0,
            receivedQty: 0,
            items: []
          }
        }
        products[order.productId].quantity += order.quantity
        products[order.productId].receivedQty += order.receivedQty
        products[order.productId].items.push(order)
      })
      this.printProducts = products
    },
    async getOverviewByOrderNo () {
      try {
        this.loading = true
        const { data } = await PurchaseOrdersService.getOverviewByOrderNo(this.$route.params.orderNo)
        this.purchaseOrderInfo = data
        this.groupProduct()
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    print () {
      this.printTime = new Date().toString()
      setTimeout(() => {
        window.print()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-bar-title {
  display: flex;
  width: 230mm;
  margin: 0 auto;
  > button {
    margin: 0 5px;
  }
}
.page-a4-title {
  display: flex;
  align-items: center;
}
</style>

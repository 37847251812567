<template>
  <div
    size="A4"
    class="page-a4 container elevation-5">
    <v-row class="head-a4">
      <v-col cols="auto">
        <div class="head-title">
          <slot name="title" />
        </div>
        <div class="head-note-detail">
          <table class="mb-6">
            <tbody>
              <tr>
                <td class="pr-4">
                  PO No :
                </td>
                <td> {{ purchaseOrderInfo.orderNo }}</td>
              </tr>
              <tr>
                <td class="pr-4">
                  Received Orders :
                </td>
                <td>
                  <span
                    v-if="purchaseOrderInfo.receivedQuantity === purchaseOrderInfo.totalQuantity"
                    class="green--text">
                    {{ purchaseOrderInfo.receivedText }}
                  </span>
                  <span
                    v-else-if="purchaseOrderInfo.receivedQuantity && purchaseOrderInfo.totalQuantity"
                    class="orange--text">
                    {{ purchaseOrderInfo.receivedText }}
                  </span>
                  <span
                    v-else-if="!purchaseOrderInfo.receivedQuantity"
                    class="red--text">
                    {{ purchaseOrderInfo.receivedText }}
                  </span>
                </td>
              </tr>
              <tr class="pr-4">
                <td>
                  <p class="text-bold mt-4">
                    ผู้ขาย
                  </p>
                </td>
              </tr>
              <tr>
                <td class="pr-4">
                  ชื่อผู้ขาย / Vendor Name :
                </td>
                <td>
                  {{ senderInfo.name }}
                </td>
              </tr>
              <tr>
                <td class="pr-4">
                  สาขา / Branch :
                </td>
                <td>
                  {{ senderInfo.branch }}
                </td>
              </tr>
              <tr>
                <td class="pr-4">
                  ผู้ติดต่อ / Contact Person :
                </td>
                <td>
                  {{ senderInfo.contactName || 'ไม่ระบุ' }}
                </td>
              </tr>
              <tr>
                <td class="pr-4">
                  เบอร์ติดต่อ / Tel :
                </td>
                <td>
                  {{ senderInfo.phoneNumber || 'ไม่ระบุ' }}
                </td>
              </tr>
              <tr>
                <td class="pr-4">
                  E-mail :
                </td>
                <td>
                  {{ senderInfo.email || 'ไม่ระบุ' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
    <v-row class="note-item-list">
      <v-col
        :cols="12"
        class="head-item-list">
        <h4>รายการสินค้า</h4>
      </v-col>
    </v-row>
    <div v-if="Object.keys(printProducts).length > 0">
      <v-row
        v-for="(product, key) in printProducts"
        :key="key"
        class="note-item page-break">
        <v-col
          :cols="2"
          class="note-item-image">
          <img
            :src="`${product.imageURL ? `${product.imageURL}?w=150` : noPicture}`"
            widh="150"
          />
        </v-col>
        <v-col
          :cols="10"
          class="pl-0">
          <div>{{ product.name }}</div>
          <div class="product-model mt-1">
            {{ product.model }}
          </div>
          <v-simple-table
            class="sku-list"
            dense>
            <thead>
              <tr>
                <th
                  v-for="(head, headIndex) in printHeaderTbl"
                  :key="`head-${headIndex}`"
                  :class="head.align"
                  :width="head.width"
                  class="capitalize">
                  {{ head.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sku, skuIndex) in product.items"
                :key="`sku-${skuIndex}`">
                <td>
                  <span>
                    {{ sku.itemNo || '-' }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ sku.color.toUpperCase() || '' }} - {{ sku.size.toUpperCase() || '' }}
                  </span>
                </td>
                <td
                  class="text-right">
                  <span>
                    {{ sku.receivedQty | showNumberFormat() }} / {{ sku.quantity | showNumberFormat() }}
                  </span>
                </td>
                <td width="13px">
                  <v-text-field
                    hide-details
                    readonly
                    dense></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col :cols="12">
          <v-divider />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import NoPicture from '@/assets/image/no_picture_available.png'

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    },
    createBy: {
      type: String,
      default: '-'
    },
    updateBy: {
      type: String,
      default: '-'
    },
    printTime: {
      type: String,
      default: '-'
    },
    printBy: {
      type: String,
      default: '-'
    },
    updateTime: {
      type: String,
      default: '-'
    },
    note: {
      type: String,
      default: ''
    },
    purchaseOrderInfo: {
      type: Object,
      default: () => {}
    },
    printProducts: {
      type: Object,
      default: () => {}
    },
    senderInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      printHeaderTbl: [
        { text: 'Code', align: 'text-left', width: '100px;' },
        { text: 'Size / Color', align: 'text-left', width: '100px;' },
        { text: 'Received', align: 'text-right', width: '100px;' },
        { text: 'Count', align: 'text-center', width: '100px;' }
      ],
      noPicture: NoPicture
    }
  },
  computed: {
    printAt () {
      return this.getTime(this.printTime)
    },
    updateAt () {
      return this.getTime(this.updateTime)
    }
  },
  methods: {
    getTime (time) {
      if (time && time !== '-') {
        return this.$dayjs(time).locale('th').format('LLL')
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
div[size="A4"] {
  display: block;
  width: 210mm;
  min-height: 297mm;
  margin: 10px auto;
  padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  .col {
    position: relative;
  }
}
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-size: 26px;
  }
}
.head-note-detail p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: 1.25rem;
}
.head-item-list h4 {
  padding: 5px 0 5px 5px;
  border: 0;
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
}

</style>

<style lang="scss">
td, th {
  padding: 4px !important;
}
tr {
  vertical-align: top !important;
}
.v-data-table.sku-list tbody tr td {
  border-bottom: 5px solid #fff !important;
  height: 42px !important;
}
tr.tr-error {
  background-color: #f2191726!important;
}
tr.tr-error:hover {
  background-color: #f2191716 !important;
}
@media print {
  tbody tr.tr-error {
    background-color: transparent !important;
  }
}
.product-model {
  font-size: 12px !important;
  color: #3c3c3c;
  margin-top: -5px;
}
</style>
